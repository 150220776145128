import { Component, Vue, Prop } from "vue-property-decorator";
import { CardPaymentSystem } from "@/types/card";

@Component
export default class CardPreview extends Vue {
  @Prop({ type: String, default: CardPaymentSystem.VISA })
  private readonly paymentSystem!: CardPaymentSystem;
  @Prop({
    type: String,
  })
  private readonly binName?: string;
  @Prop({
    type: String,
  })
  private readonly cardHolderName?: string;

  private get truncatedCardHolderName() {
    if (!this.cardHolderName?.length) return "";

    return this.cardHolderName.length > 17
      ? `${this.cardHolderName.slice(0, 17)}...`
      : this.cardHolderName;
  }

  private get paymentSystemOpts() {
    if (this.paymentSystem === CardPaymentSystem.VISA) {
      return {
        x: 266,
        y: 33,
        height: 17,
        iconUrl: require("./img/paymentSystem/visa.svg"),
      };
    }

    if (this.paymentSystem === CardPaymentSystem.MASTERCARD) {
      return {
        x: 266,
        y: 24,
        height: 35,
        iconUrl: require("./img/paymentSystem/mastercard.svg"),
      };
    }
  }
}
