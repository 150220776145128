import { Vue, Component, Prop } from "vue-property-decorator";
import { Bank, BankType } from "@/types/bank";
import { formatPercent, formatMoney } from "@helpers";
import { CardType } from "@/types/card";
import { HtmlElementId } from "@/types/element";

import CardPreview from "../CardPreview/CardPreview.vue";
import { Currency } from "@/types/currency";

@Component({
  components: {
    CardPreview,
  },
})
export default class CardInfo extends Vue {
  @Prop({ type: String, required: true })
  private readonly cardHolderName!: string;
  @Prop({ type: Object }) private readonly selectedBank?: Bank;
  @Prop({ type: String, default: "none" })
  private readonly cardPreviewMaxWidth!: string;
  @Prop({ type: String, required: true }) private readonly cardType!: CardType;

  private get htmlElementId() {
    return {
      issueCardBankFeeInfo: HtmlElementId.issueCardBankFeeInfo,
    };
  }

  private get bankName() {
    return this.selectedBank?.name;
  }

  private get bankCardPaymentSystem() {
    return this.selectedBank?.info.paymentSystem;
  }

  private get bankFeeInfo() {
    const bankCurrency = this.selectedBank?.currency || Currency.EUR;

    const transactionFee = this.selectedBank?.transactionFee
      ? formatPercent({ value: this.selectedBank.transactionFee })
      : "";

    const transactionFeeCurrency =
      typeof this.selectedBank?.transactionFeeCurrency === "number" &&
      this.selectedBank.transactionFeeCurrency >= 0
        ? formatPercent({ value: this.selectedBank.transactionFeeCurrency })
        : "";

    const fixedFee = this.selectedBank?.fixedFee
      ? formatMoney({
          value: this.selectedBank.fixedFee,
          currency: bankCurrency,
        })
      : "";

    const fixedFeeThreshold = this.selectedBank?.fixedFeeThreshold
      ? formatMoney({
          value: this.selectedBank.fixedFeeThreshold,
          currency: bankCurrency,
        })
      : "";

    const bankExchangeFeeMult = this.selectedBank?.bankExchangeFeeMult
      ? formatPercent({
          value: this.selectedBank.bankExchangeFeeMult,
          isDecimal: false,
        })
      : "";

    const extraBankExchangeFeeMult = this.selectedBank?.extraBankExchangeFeeMult
      ? formatPercent({
          value: this.selectedBank.extraBankExchangeFeeMult,
          isDecimal: false,
        })
      : "";

    const cardIssueCost = this.selectedBank?.cardIssueCost
      ? formatMoney({
          value: this.selectedBank.cardIssueCost,
          currency: bankCurrency,
        })
      : "";

    const declineTransactionFee = this.selectedBank?.declineTransactionFee
      ? formatMoney({
          value: this.selectedBank.declineTransactionFee,
          currency: bankCurrency,
        })
      : "";

    const info: {
      label: string;
      value?: string;
    }[] = [];

    const infoPart1: {
      label: string;
      value: string;
    }[] = [
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.transaction_fee"
        ),
        value: transactionFee,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.transaction_fee_with_currency",
          bankCurrency
        ),
        value: transactionFeeCurrency,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.another_transaction_fee"
        ),
        value: bankExchangeFeeMult,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.additional_transaction_fee_another_currency"
        ),
        value: extraBankExchangeFeeMult,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.min_transaction_fee"
        ),
        value: fixedFee,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.decline_transaction_fee"
        ),
        value: declineTransactionFee,
      },
      {
        label: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.fixed_fee_for_any_operation"
        ),
        value: fixedFeeThreshold,
      },
      {
        label: this.$vuetify.lang.t("$vuetify.fields.card_issue"),
        value: cardIssueCost,
      },
    ].filter(({ value }) => !!value);

    if (infoPart1.length) {
      info.push(...infoPart1);

      if (
        this.cardType === CardType.POSTPAID &&
        this.selectedBank?.bankType === BankType.ADMEDIA
      ) {
        info.push({
          label: this.$vuetify.lang.t(
            "$vuetify.info.card_frozen_no_transactions_more_two_weeks"
          ),
        });
      }

      info.push({
        label: this.$vuetify.lang.t(
          "$vuetify.info.commission_canceled_transactions_non_refundable"
        ),
      });

      this.selectedBank?.externalInfo?.forEach(({ transKey, params = [] }) => {
        info.push({
          label: this.$vuetify.lang.t(transKey, ...params),
          value: "",
        });
      });
    }

    return info;
  }

  private get alertText() {
    const socials = [
      {
        text: "Google",
        enabled: this.selectedBank?.info.recommendations.google,
      },
      {
        text: "Facebook",
        enabled: this.selectedBank?.info.recommendations.facebook,
      },
      {
        text: "TikTok",
        enabled: this.selectedBank?.info.recommendations.tikTok,
      },
      {
        text: "AI",
        enabled: this.selectedBank?.info.recommendations.ai,
      },
      {
        text: "Hosting",
        enabled: this.selectedBank?.info.recommendations.hosting,
      },
      {
        text: "SEO",
        enabled: this.selectedBank?.info.recommendations.seo,
      },
      {
        text: "Taboola",
        enabled: this.selectedBank?.info.recommendations.taboola,
      },
    ].reduce((acc, { text, enabled }) => {
      if (!enabled) return acc;

      if (acc.length) {
        acc += ", ";
      }

      acc += text;

      return acc;
    }, "");

    return this.$vuetify.lang.t(
      "$vuetify.info.pay_attention_for_advertising_purposes",
      socials
    );
  }
}
